@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@font-face {
  font-family: EnchantedLand;
  src: url("./fonts/EnchantedLand.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin: 0;
}

.page-container {
  min-height: calc(100vh - 188px);
}
.css-1yx5l3v, .css-p7auv0-MuiContainer-root {  /* TO-DO Hack */
  margin-top: 144px !important;
  margin-bottom: 100px !important;
}
.css-1wnsr1i {
  border: 0px !important;
  border-radius: 5px !important;
}
.titles {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 18px;
  text-align: center;
}
.description-title {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.33;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}
.mainTitle {
  color: #f3f3f3;
  font-family: EnchantedLand;
  font-weight: 300;
  font-size: 50px;
  margin-bottom: 30px;
  text-align: center;
}
.mainTitleFAQ {
  color: #f3f3f3;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  padding-top: 100px;
  text-transform: uppercase;
  text-align: center;
}
@media only screen and (min-width: 800px) {
  .mainTitleFAQ {
    font-size: 40px;
  }
  .mainTitle {
    font-size: 75px;
  }
}

@media only screen and (max-width: 800px) {
  .mainTitleFAQ {
    font-size: 20px;
  }
  .mainTitle {
    font-size: 40px;
  }
}
.mainTitleLogin{
  color: #030303;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 35px;
  padding-top: 30px;
  text-align: center;
}
.subTitle {
  color: #f3f3f3;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 30px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}
.belowButtonTitle {
  color: #f3f3f3;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}
.infoSection{
  background-image: url("https://mui.com/static/themes/onepirate/productCurvyLines.png");
}

.calendar-alert {
  margin-top: 20px;
}
.calendar-alert .MuiAlert-icon {
  align-items: center;
}
.calendar-alert.MuiAlert-standardSuccess,
.calendar-alert.MuiAlert-standardSuccess .MuiAlert-icon {
  background-color: rgb(12, 19, 13);
  color: rgb(204, 232, 205);
}
.calendar-alert.MuiAlert-standardError,
.calendar-alert.MuiAlert-standardError .MuiAlert-icon {
  background-color: rgb(22, 11, 11);
  color: rgb(244, 67, 54);
}
.calendar-alert.fade-out {
  opacity: 0;
  transition: opacity 1s linear;
}
.calendar-alert.hidden {
  display: none;
}

.disclaimer {
  text-align: center;
  font-size: 10px;
}
.css-1wnsr1i {
  width: auto;
}
.FAQ-Accordion {
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 50px;
}
.FAQ-container {
  background-color: #001e3c;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('https://images.unsplash.com/photo-1579571373417-1bff1dc3b572?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80');
}
.login-container {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-image: url("https://images.unsplash.com/photo-1572635148619-a66d0ddbc9bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  background-repeat: no-repeat;
}
.SignupSubtitle {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.signup-container {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background-image: url("https://images.unsplash.com/photo-1560111161-564c7020f5c2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.setup-container,
.checkout-container {
  display: flex;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1522188167899-88c4b07cf8e4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2216&q=80");
  padding-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.profile-container {
  display: flex;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1574876881304-324d606d6dc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80");
  padding-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* HACKS FOR PROFILE ON MOBILE */
@media (max-width: 475px) {
  .css-jbsa36-MuiPaper-root, .css-mrbg38 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .css-cuefkz-MuiContainer-root, .css-1m6pqln {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
/* END OF HACKS FOR PROFILE ON MOBILE */
.MuiTabs-flexContainer {
  justify-content: space-between;
}
.MuiFormHelperText-root.helper-text-success {
  color: green;
  font-weight: 700;
}
.fireworkLogo {
  margin-top: 8px;
  width: 50px;
  transform: scale(1.25);
  transition: transform .2s;
}
.fireworkLogo:hover {
  transform: scale(1.5);
}
.pricingCards {
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 30px;
}
@media only screen and (min-width: 900px) {
  .pricingCards {
    display: flex;

  }
  .pricingCard {
    min-width: 400px;
  }
}

@media only screen and (max-width: 900px) {
  .pricingCards {
    display: block;
    width: 100%;
  }
  .pricingCard {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.css-pwngrv-MuiPaper-root-MuiCard-root {
  margin-right: 50px;
}
.pricingSubTitle {
  text-align: center;
  padding-bottom: 10px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}
.pricingSubTitlePrice {
  text-align: center;
}

.pricingSubSubTitle {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}
.pricingTitle {
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin: 0 auto;
  width: 100%;
}
.pricingImage {
  width: 100px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-top: 20px;
  transition-duration: 1s;
}
.pricingImage:hover {
  transform: scale(1.1);
}
.pricingTitleCards {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 0 auto;
  width: 100%;
  text-transform: uppercase;
}
.pricingContainer {
  padding-bottom: 100px;
  padding-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://upload.wikimedia.org/wikipedia/commons/0/06/Star_Wars-_Galaxy%27s_Edge_Disneyland_Fireworks_2019.jpg');
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icons {
  width: 50px !important;
  height: 50px !important;
  z-index: 0;
}
.css-1t6e9jv-MuiCardActions-root, .css-3zukih { /* TO-DO Hack */
  align-items: center;
  margin: 15px;
  text-align: center;
  background-color: #08182F;
  color: #f3f3f3;
  align-items: center;
}
.pricingSubTitleAnnual {
  color: #e14040;
  text-align: center;
  padding-bottom: 10px;

}
.pricingSubTitleAnnual-none {
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
}
.subTitle-Pricing {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: white;
  text-align: center;
  padding-top: 20px;
}
.button-FAQ {
  width: 300px;
  height: 50px;
  border-radius: 0 !important;
  font-family: 'Roboto', sans-serif;
}

/* Calendar Styling */
.rmdp-wrapper {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.rmdp-day, .rmdp-week-day {
  font-family: 'Roboto', sans-serif;
}
.rmdp-header-values {
  font-family: 'Roboto', sans-serif;
}

/* Footer Icons */
.soc {
  display: block;
  font-size: 0;
  list-style: none;
  margin: 0;
  padding: 48px;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  width: 100%;
}
.soc li {
  display: inline-block;
  margin: 12px;
  margin: 1.5rem;
}
.soc a, .soc svg {
  display: block;
}
.soc a {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
}
.soc svg {
  height: 100%;
  width: 26px;
}
.soc em {
  font-size: 14px;
  line-height: 1.5;
  margin-top: -0.75em;
  position: absolute;
  text-align: center;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
}

.icon-email, .icon-facebook, .icon-instagram, .icon-linkedin, .icon-twitter, .icon-youtube {
  border-radius: 100%;
  color: #fff;
  fill: #fff;
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  -webkit-transition: background-color 0.5s, -webkit-transform 0.5s ease-out;
  transition: background-color 0.5s, -webkit-transform 0.5s ease-out;
  transition: background-color 0.5s, transform 0.5s ease-out;
  transition: background-color 0.5s, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.icon-email {
  color: #ed4035;
  fill: #ed4035;
}
.icon-email {
  background: #fff;
}
.icon-facebook {
  color: #3578E5;
  fill: #3578E5;
}
.icon-facebook {
  background: #fff;
}
.icon-instagram {
  color: #6c40bb;
  fill: #6c40bb;
}
.icon-instagram {
  background: #fff;
}
.icon-linkedin {
  color: #1c8cb4;
  fill: #1c8cb4;
}
.icon-linkedin {
  background: #fff;
}
.icon-twitter {
  color: #1da1f2;
  fill: #1da1f2;
}
.icon-twitter {
  background: #fff;
}
.icon-youtube {
  color: #07182f;
  fill: #07182f;
}
.icon-youtube {
  background: #fff;
}

/* Terms and Conditions  */
.terms {
  font-weight: 700;
  flex-direction: row;
  justify-content: center;
}
@media only screen and (min-width: 700px) {
  .terms {
    display: flex;
  }
}
@media only screen and (max-width: 700px) {
  .terms {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .css-oz8sc9-MuiListItem-root, .css-6bitvb {  /* Hack */
    text-align: center !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
.termsTitle {
    color: #030303;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 30px;
    padding-top: 100px;
    text-align: center;
}
.termsBody{
  max-width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}
.privacyTitle {
  color: #030303;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 30px;
  padding-top: 100px;
  text-align: center;
}
.privacyBody{
  max-width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}
.cookieTitle {
  color: #030303;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 30px;
  padding-top: 100px;
  text-align: center;
}
.cookieBody{
  max-width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}
.disclaimerTitle {
  color: #030303;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 30px;
  padding-top: 100px;
  text-align: center;
}
.disclaimerBody{
  max-width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}
/* Signup Modal */
.signup-modal-title{
  font-size: 35px;
  font-family: 'Roboto', sans-serif;

}

/* Large Calendar Styling */
@media (min-width: 800px) {
  .rmdp-mobile .rmdp-day,
  .rmdp-mobile .rmdp-week-day {
    height: 50px;
    width: 50px;
  }
  .rmdp-mobile .only.rmdp-month-picker,
  .rmdp-mobile .only.rmdp-year-picker {
    width: 250px;
  }
  .rmdp-mobile .rmdp-header,
  .rmdp-mobile .rmdp-panel-header {
    height: 20px;
  }
  .rmdp-mobile .rmdp-day span {
    font-size: 18px;
  }
  .rmdp-mobile .rmdp-day-picker {
    padding: 10px 5px;
  }
  .rmdp-mobile.rmdp-single .rmdp-day-picker {
    padding: 10px;
  }
  .rmdp-mobile .rmdp-arrow-container {
    height: 40px;
    width: 40px;
    margin: 0px;
  }
  .rmdp-mobile .rmdp-panel-header {
    margin-bottom: 30px;
  }
  .rmdp-arrow-container.disabled {
    border-right-width: 5px;
    border-bottom-width: 5px;
  }
  .rmdp-mobile .rmdp-arrow {
    padding: 2px;
    height: 15px;
    width: 15px;
    margin-top: 6px;
    border-right-width: 4px !important;
    border-bottom-width: 4px !important;
  }
  .rmdp-mobile .rmdp-action-button {
    font-size: 18px;
    padding: 5px 5px;
  }

  .rmdp-mobile .rmdp-action-button:first-child {
    padding-right: 10px;
  }

  .rmdp-mobile .rmdp-panel-header {
    padding-bottom: 20px;
  }

  .rmdp-mobile .rmdp-left i,
  .rmdp-mobile .rmdp-right i {
    margin-top: 13px;
  }

  .rmdp-week-day {
    font-size: 18px;
  }

  .rmdp-mobile .rmdp-header,
  .rmdp-mobile .rmdp-panel-header {
    font-size: 18px;
  }

  .rmdp-mobile .rmdp-header {
    margin-top: 20px;
    height: 30px;
  }

}

.subscriptionPasses:hover{
  background-color: #f5f5f5;
  color: #707070;
}

.subscriptionStatus{
  text-align: 'center',
}
